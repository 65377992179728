// GLOBAL VARIABLES
export const GV =  {
    MinLoadTime: 500, //minimum time for first visit (not loaded, uncached) of a page.
    MinReloadTime: 500, //minimum time for visiting a visited (loaded, cached) page.
    FirstLoad: false,
    FirstLoadDelay: 750,
}

// RENSPONSIVE VARIABLES
export const RP =  {
    width: {
        smaller: 450,
        mobile: 750,
        tablet: 1024,
        desktop: 1280
    },
    height: {
        short: 350
    }
}
