import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import { Helmet } from 'react-helmet';
import { Quick } from 'utils/common';

export default class Layout extends React.Component {
	componentDidMount() {
		LayoutFunction.resizeAdd();
		LayoutFunction.fitheight();
	}
	componentWillUnmount() {
		LayoutFunction.exit();
	}
	render() {
		const props = this.props;
		if (typeof document !== `undefined`) {
			document.body.id = this.props.mainId;
		}
		return (
			<StaticQuery
				query={query}
				render={(data) => {
					const setting = data.setting.frontmatter;
					const webname = data.setting.frontmatter.web_name;
					const headertitle = data.setting.frontmatter.header_title;
					let seo_image = '';
					if (setting.seo.seo_image) {
						seo_image = `https://malt.studio${setting.seo.seo_image.childImageSharp.fixed.src}`;
					}
					const seo = {
						desc: setting.seo.seo_keywords,
						keywords: setting.seo.seo_keywords,
						image: seo_image,
						url: 'https://malt.studio'
					};

					return (
						<main id={props.mainId} className={props.mainClass}>
							<Helmet>
								<title>{props.titleText ? `${props.titleText} | ${webname}` : webname}</title>
								<meta name="description" content={seo.desc} />
								<meta name="image" content={seo.image} />
								<meta name="keywords" content={seo.keywords} />
								{seo.url && <meta property="og:url" content={seo.url} />}

								{props.titleText ? (
									<meta property="og:title" content={`${props.titleText} | ${webname}`} />
								) : (
									<meta property="og:title" content={webname} />
								)}
								{seo.desc && <meta property="og:description" content={seo.desc} />}
								{seo.image && <meta property="og:image" content={seo.image} />}
								<meta name="twitter:card" content="summary_large_image" />

								{props.titleText ? (
									<meta property="twitter:title" content={`${props.titleText} | ${webname}`} />
								) : (
									<meta property="twitter:title" content={webname} />
								)}
								{seo.desc && <meta name="twitter:description" content={seo.desc} />}
								{seo.image && <meta name="twitter:image" content={seo.image} />}
							</Helmet>

							<Header headerText={headertitle} />
							{props.children}
						</main>
					);
				}}
			/>
		);
	}
}

const LayoutFunction = {
	resizeDelayTimer: null,
	resizeInit: false,
	resizeDelay: 250,
	clear: () => {
		if (typeof document !== `undefined`) {
			//	CLASS TO HIDE CONTENT DURING RESIZING
			document.body.classList.add('resizing');
		}
	},
	render: () => {
		if (typeof document !== `undefined`) {
			//	CLASS TO RERENDER CONTENT DURING RESIZING
			document.body.classList.remove('resizing');
		}
	},
	exit: () => {
		if (typeof document !== `undefined`) {
			//	add loading
			document.body.classList.add('loading');
		}
		LayoutFunction.resizeRemove();
	},
	resizeIOSCheck: null,
	fitheight: () => {
		const elems = Quick.qSAll('.fitheight');

		if (elems.length > 0) {
			elems.forEach((elem) => {
				if (typeof window !== `undefined`) {
					elem.style.height = `${window.innerHeight}px`;
				}
			});
		}
	},
	resize: () => {
		const resizeFunction = () => {
			LayoutFunction.clear();
			if (LayoutFunction.resizeDelayTimer != null) clearTimeout(LayoutFunction.resizeDelayTimer);
			LayoutFunction.resizeDelayTimer = setTimeout(() => {
				LayoutFunction.render();
			}, LayoutFunction.resizeDelay);
			LayoutFunction.fitheight();
		};
		resizeFunction();
	},
	resizeAdd: () => {
		if (typeof window !== `undefined`) {
			if (!LayoutFunction.resizeInit) {
				LayoutFunction.resizeInit = true;
				window.addEventListener('resize', LayoutFunction.resize, { passive: true });
			}
		}
	},
	resizeRemove: () => {
		if (typeof window !== `undefined`) {
			LayoutFunction.resizeInit = false;
			window.removeEventListener('resize', LayoutFunction.resize, { passive: true });
		}
	}
};
const query = graphql`
	query {
		setting: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				title
				web_name
				header_title
				seo {
					seo_keywords
					seo_shortdesc
					seo_image {
						childImageSharp {
							fixed(width: 1200) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	}
`;
