import React from 'react';
import { TweenMax, Power0 } from 'gsap';
import isTouchDevice from 'is-touch-device';
import { StaticQuery, graphql, Link } from 'gatsby';

import { disableBodyScroll, enableBodyScroll,clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Quick, RespCheck } from 'utils/common';

export default class Header extends React.Component {
	componentDidMount() {
		headerFunction.init();
		headerFunction.resize();
		headerFunction.resizeAdd();
	}
	componentWillUnmount() {
		headerFunction.resizeRemove();
		headerFunction.reset();
	}
	render() {
		return (
			<StaticQuery
				query={graphql`
					query {
						setting: markdownRemark(
							frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }
						) {
							frontmatter {
								title
								web_name
								header_title
								seo {
									seo_keywords
									seo_shortdesc
								}
								contact {
									email
									instagram
								}
							}
						}
						about: markdownRemark(frontmatter: { issetting: { eq: false }, contenttype: { eq: "about" } }) {
							frontmatter {
								title
								slug
							}
						}
						works: markdownRemark(frontmatter: { issetting: { eq: false }, contenttype: { eq: "works" } }) {
							frontmatter {
								title
								slug
							}
						}
					}
				`}
				render={(data) => {
					let mobileContact = false;
					if (data.setting.frontmatter.contact.email || data.setting.frontmatter.contact.instagram)
						mobileContact = true;
					return (
						<header>
							<div className="malt_logo">
								<Link className="" to="/">
									{data.setting.frontmatter.header_title ? (
										data.setting.frontmatter.header_title
									) : (
										'MALT Studio'
									)}
									<span className="logo_blocker" />
								</Link>
								<Link className="mobile" to={`/${data.works.frontmatter.slug}`}>
									{data.setting.frontmatter.header_title ? (
										data.setting.frontmatter.header_title
									) : (
										'MALT Studio'
									)}
									<span className="logo_blocker" />
								</Link>
							</div>
							<div className="header_nav_desk">
								<Link activeClassName="active" className="works" to={`/${data.works.frontmatter.slug}`}>
									{data.works.frontmatter.slug}
								</Link>
								<Link activeClassName="active" className="about" to={`/${data.about.frontmatter.slug}`}>
									{data.about.frontmatter.slug}
								</Link>
							</div>
							<div className="mobile_hamburger firsttrigger">
								<span />
								<span />
								<span />
								<span />
							</div>
							<div className="mobile_contact">
								{mobileContact && <span>&mdash;</span>}
								{data.setting.frontmatter.contact.email && (
									<a
										className=""
										href={`mailto:${data.setting.frontmatter.contact.email}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data.setting.frontmatter.contact.email}
									</a>
								)}

								{data.setting.frontmatter.contact.instagram && (
									<a
										href={`https://www.instagram.com/${data.setting.frontmatter.contact.instagram}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data.setting.frontmatter.contact.instagram}
									</a>
								)}
							</div>
						</header>
					);
				}}
			/>
		);
	}
}

const headerFunction = {
	mobileMenuToggle: false,
	headerLogoAnimation: false,
	init: () => {
		//RESET VARIABLES
		headerFunction.toggleMobileMenu(true);
		headerFunction.headerLogoAnimation = false;
		headerFunction.mobileMenuToggle = false;

		// HEADER LOGO HOVER ANIMATION
		document.querySelector('header .malt_logo').onmouseenter = () => {
			if (!headerFunction.headerLogoAnimation && !isTouchDevice()) {
				headerFunction.headerLogoAnimation = true;
				const logo_blockers = document.querySelectorAll('header .malt_logo .logo_blocker');
				logo_blockers.forEach((logo_blocker) => {
					TweenMax.to(logo_blocker, 0.5, {
						left: '120%',
						ease: Power0.easeNone,
						onComplete: () => {
							logo_blocker.removeAttribute('style');
							headerFunction.headerLogoAnimation = false;
						}
					});
				});
			}
		};

		// MENU TRIGGER
		document.querySelector('.mobile_hamburger').onclick = () => {
			if (RespCheck.width.mobile()) {
				if (headerFunction.mobileMenuToggle) {
					headerFunction.toggleMobileMenu(true);
				} else {
					headerFunction.toggleMobileMenu(false);
				}
			}
		};
	},
	toggleMobileMenu: (close = true) => {
		if (close) {
			if (headerFunction.mobileMenuToggle) {
				headerFunction.mobileMenuToggle = false;
				document.body.classList.remove('mobile_menu_open');
				Quick.qS('.mobile_hamburger').classList.remove('firsttrigger');
				console.log('enable scroll');
				enableBodyScroll(document.querySelector('header'));
			}
		} else {
			if (!headerFunction.mobileMenuToggle) {
				Quick.qS('.mobile_hamburger').classList.add('firsttrigger');
				document.querySelector('.mobile_hamburger').onmouseleave = () => {
					Quick.qS('.mobile_hamburger').classList.remove('firsttrigger');
					document.querySelector('.mobile_hamburger').onmouseleave = null;
				};
				document.body.classList.add('mobile_menu_open');
				headerFunction.mobileMenuToggle = true;

				console.log('disable scroll');
				disableBodyScroll(document.querySelector('header'));
				if (!headerFunction.headerLogoAnimation && !isTouchDevice()) {
					headerFunction.headerLogoAnimation = true;

					const logo_blockers = document.querySelectorAll('header .malt_logo .logo_blocker');
					logo_blockers.forEach((logo_blocker) => {
						TweenMax.to(logo_blocker, 0.5, {
							left: '120%',
							ease: Power0.easeNone,
							onComplete: () => {
								logo_blocker.removeAttribute('style');
								headerFunction.headerLogoAnimation = false;
							}
						});
					});
				}
			}
		}
	},
	reset: () => {
		headerFunction.toggleMobileMenu(true);
		clearAllBodyScrollLocks();
		enableBodyScroll(document.querySelector('header'));
	},
	resizeAdd: () => {
		if (typeof window !== `undefined`) {
			window.addEventListener('resize', headerFunction.resize, { passive: true });
		}
	},
	resizeRemove: () => {
		if (typeof window !== `undefined`) {
			window.removeEventListener('resize', headerFunction.resize, { passive: true });
		}
	},
	resize: () => {
		if (!RespCheck.width.mobile()) {
			headerFunction.toggleMobileMenu(true);
			clearAllBodyScrollLocks();
		}
	}
};
